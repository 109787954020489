.sliderContainer{
    width: 100%;
}
.sliderImg{
    margin: 0 auto;
    padding: 30px;
    max-width: 100%;
    height: auto;
}
.topImage{
    display: block;
    margin: 0 auto;
    padding: 30px;
    max-width: 100%;
    height: auto;
}
.footer{
    display: flex;
    flex-flow: wrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: white;;
    height: 100px;
    justify-content: space-around;
    left: 0;
}
.footerButton{
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.0;
    display: inline-block;
    padding: 1rem 4rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    letter-spacing: 0.1em;
    color: #212529;
    border-radius: 0.5rem;
    color: #fff !important;
    background-color: #eb6100;
    margin: 10px;
}
.tableHeader{
    width: 150px;
}
.container{
    margin-bottom: 150px;
}
.thankspage_box {
    width: 80%;
    margin: 0 auto;
}
.thankspage {
    font-weight: 100;
    text-align: left;
    padding-left: 100px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148);
}
.valueField{
    white-space: pre-line;
}
@media screen and (max-width:600px) {
    .thankspage_box {
        width: 80%;
        margin: 0 auto;
    }
    .thankspage {
        font-weight: 60;
        text-align: left;
        padding-left: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgb(79, 98, 148);
    }
}